.Cards-so {
    width: 100%;
    margin: 10px 0;
}

.Cards-so h4 {
    font-size: 45px;
    padding: 20px 0;
    margin: 0 15px;
    color: white;
    margin-bottom: 10px;
}

.card-so.m-2 img {
    background: none;
    outline: none;
    height: 18rem;
    margin: 0;
    border-radius: 10px;
}
.card-so.m-2 {
    background: none;
    outline: none;
    margin: 0;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 10px;
}
.services-card{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 10px;
    margin-bottom: 10px;
}


.card-so.m-2:hover {
    transform: translateY(-10px);
    transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke;
    border-radius: 10px;
    /* padding: 20px; */
}

/* .card-so.m-2:hover img {
    transform: scale(1.1);

} */

.card-title {
    color: orange;
    font-weight: 600;
    margin: 10px 0;
}

.btn.btn-warning {
    margin-top: 10px;
}

.container {
    margin: 20px 0;
}

.pb-4.border-bottom {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}

@media (max-width: 991px) {
    .Cards-so {
        width: 100%;
        margin:  0;
    }
    
}
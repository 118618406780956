
.intrall-top  {
    width: 100%;
    height: auto;
    padding: 20px;
}


.intrall .fa-solid {
    color: white;
    font-size: 55px;
    margin-right: 10px;
    transition: .3s;
    padding-right: 10px;
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    animation-delay: 3s;
}
.intrall h4 {
    color: white;
    font-size: 35px;
    margin: 10px;
    transition: .3s;
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    animation-delay: 3s;
}

.intrall h1 {
    color: orange;
    font-size: 50px;
    margin: 0 20px;
    transition: .3s;
    opacity: 0;
    animation: slideRight 1s ease forwards;
    animation-delay: 2s;
}

.intrall p {
    color: white;
    font-size: 20px;
    margin: 20px;
    transition: .3s;
    opacity: 0;
    animation: slideBottom 1s ease forwards;
    animation-delay: 3s;
}
 img {
    font-size: 20px;
    margin: 20px;
    width: 550px;
    height: auto;

}

.intrall.right {
    color: orange;
    font-size: 50px;
    margin: 0 20px;
    justify-content: center;
    text-align: right;
    transition: .3s;
    width: 100%;
    opacity: 0;
    animation: slideBottom 1s ease forwards;
    animation-delay: 2s;
}

.Solution-contrall {
    max-width: 100%;
    margin: auto;
    padding: 80px;
    padding-top: 30px;
    width: 100%;
    border-radius: 5px;

}

.materials {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 10px;
    margin-bottom: 10px;

}

.namber-box {
    padding: 0%;
    margin: 0;
}

.namber {
    display: flex;
    padding: 0%;
    margin: 0%;
    align-items: baseline;
}
.namber-box:hover {
    transform: translateY(-20px);
    transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke;
    border-radius: 10px;
    transition: .3s;
}

.namber h1 {
    color: white;
    font-size: 70px;
    height: auto;
    border-radius: 5px;

}

.namber h4 {
    color: orange;
    font-size: 20px;
    height: 25px;
    display: flex;
    margin-left: 0%;
    /* border-bottom: 1px solid white; */
}

.namber p {
    color: orange;
    font-size: 20px;
    height: 30px;
    align-items: first baseline;
    display: flex;
}


@media(max-width:880px) {

    .Solution-contrall {
        padding: 0px;
        margin: 0;

    }
}

@keyframes slideBottom {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
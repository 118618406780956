.container-1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(259px, 1fr));
    grid-gap: 10px;
    height: auto;
    padding: 25px;
    width: 100%;
    box-shadow: 0px 2px 0px 0px orange;
	background:linear-gradient(to bottom, #000000 2%, black 100%);
	background-color:black;
	cursor:pointer;
	color:#333333;
	font-size:15px;
	font-weight:bold;
	text-decoration:none;
	text-shadow:0px 0px 0px #f29d13;
    margin-bottom: 2px;

}

.navbar-brand-1 img {
    height: 110px;
    width: auto;
    border-radius: 10px;
    opacity: 0;
    animation: slideRight 1s ease forwards;
}

.navbar-brand-1 img:hover {
    outline: none;
    border-radius: 15px;
    box-shadow: 0 0 55px white,
        0 0 25px white, 0 0 50px white,
        0 0 100px white, 0 0 200px white;
}

.all-contact-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.contact-footer-i {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.all-contact-footer h4 {
    color: white;
    font-size: 21px;

}

.Subscribe {
    margin: 0px;
}

.Subscribe p {
    color: white;
    font-size: 14px;
    margin-bottom: 10px;
}

.Subscribe h5 {
    color: white;
    font-size: 21px;
}

.btn.text-white {
    background: orange;
}

.btn.text-white:hover {
    box-shadow: 0 0 55px white,
        0 0 25px white, 0 0 50px white,
        0 0 100px white, 0 0 200px white;
}

.nav-item i {
    color: white;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    margin-right: 10px;
}
.nav-item i:hover {
    color: orange;
    text-shadow: 0 0 55px white,
        0 0 25px white, 0 0 50px white,
        0 0 100px white, 0 0 200px white;
}
.nav-item a {
    color: white;
    line-height: 1.5;
    text-decoration: none;
    text-underline-offset: none;
}

@media(max-width:667px) {
    .navbar-brand-1 img {
        display: none;
    }

}


.search-fill {
    background: black;
    height: 600px;
    width: 100%;display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

}
.search-fill i{
    color: white;
    font-size: 7vw;
    margin: 2vw 0;
}
.search-fill h2{
    color: orange;
    font-size: 40px;
    margin-bottom: 2vw;
}
.search-fill p{
    color: white;
    font-size: 1.5vw;
}
.cry i{
    padding: 0 20px;
    color: white;
    font-size: 2vw;
}

.Slider-home{
    padding: 0px;
    margin: 0 px;
    /* border-radius: 10px; */
    border: 15px solid transparent;
  }

  .logo-slide-home img {
    width:100%;
    height:350px;
    display: block;
    padding: 10px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background-size: contain;
    object-fit: cover;
    margin-top: 10px;
    /* border-radius: 10px; */
  }
  .custom-logo-slider{
    border-radius: 10px;
  }
  .Slide-one{
    /* transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke; */
    margin-top: 25px;
    /* border-radius: 10px; */
  
  }
  .Slide-one img{
    /* border-radius: 10px; */
    background-size: contain;
  }
  .slick-next{
    display: none !important;
  }



    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
        transition: 0.5s;
    }


    body {
        margin-top: 20px;
        background: rgb(58, 58, 57);
        background: radial-gradient(circle, rgb(70, 70, 69) 0%, rgba(0, 0, 0, 1) 100%);
        
    }

.Last-footer {
    display: flex;
    justify-content: center;
    height: auto;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: white;
    padding: 15px;
    background: black;
}

/* .footer-lat img{
    height: auto;
    width: 100%;
} */
.News{
    width: 100%;
    padding: 0 20px; 
    border-bottom: 1px solid whitesmoke;
    padding-bottom: 20px;
    
}

.wrapper-news {
    max-width: 1400px;
    margin: auto;
    padding: 0 20px;
    padding-top: 30px;
    width: 100%;
    transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke;
    
    
}
.head-new{
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 20px;
    padding: 10px;
    margin-top: 20px;

}

.News h1{
    color: white;
    font-size: 35px;
    border-bottom: 1px solid goldenrod;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: 400;
}
.news-titel{
    width: auto;
    height: auto;
    
}

.news-titel h1{
    color: orange;
    font-size: 25px;
    font-weight: 600;
}
.news-titel i{
    color: whitesmoke;
    font-size: 30px;
}
.news-titel h5{
    color: whitesmoke;
    font-size: 17px;
    font-weight: 300;
}

  .Slid-news{
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
    background: none;
  
  }

  .Slid-news:hover img{
    transform: scale(1.2);
  
  }
 .about-text{
    margin-bottom: 25px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.grid-2-row-about {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    padding: 0px;
    margin: 0px;
    background: none;
    margin: 40px 0;
}
.grid-color-about {
    margin-top: 20px;
}
.grid-2-col-about {
    text-align: left;
    padding: 15px;
}

.grid-2-col.about h4 {
    color: orange;
    margin: 0 0  0px 0;
}

.grid-2-col p {
    margin: 10px 0;
    padding-left: 10px;
    text-align:left;
}
.About h4 {
    color: white;
    font-weight: 700;
    font-size: 45px;
}
.About h3 {
    color: white;
    font-weight: 700;
    margin-bottom: 10px;
}
.About h2 {
    color: white;
    font-weight: 700;
    margin-bottom: 10px;
    margin-left: 20px;
}

.heading-about h4{
    color: white;
    font-size: 30px;
}
.heading-about h3{
    color: white;
    font-size: 30px;
    font-weight: 600;
}

.grid-8-img-about {
    position: relative;
    margin-bottom: 20px;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
}
.grid-8-img-about img:hover {
    transform: scale(1.1);
}
.grid-8-mini-col h5{
color: white;
padding: 10px 0;
margin-left: 20px;
}
.grid-8-mini-col p{
color: orange;
margin-left: 20px;
}

.grid-10-img {
    overflow: hidden;
    height: 200px;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    outline: none;
}
/* grid-10-item */
.grid-10-item {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 15px;
    margin: 0%;
    padding: 0%;
}

.grid-10-item-img-about {
    width: auto;
    height:180px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-10-item-img img{
    width: 200px;
    height: auto;
    margin-right: 10px;
    overflow: hidden;
}

.grid-10-item-content h5 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.grid-10-item-content h5:hover {
    color: orangered;
    cursor: pointer;
}

.grid-10-item-content h6 {
    color: gray;
    font-size: 14px;
    font-weight: 200;
}


.grid-color-ab {
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 1px;
    margin-bottom: 10px; 
    /* transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke; */
}

.grid-10-img:hover img,
.grid-10-item:hover img {
    transform: scale(1.1);
    cursor: pointer;
}




p {
    color: var(--gray);
    font-size: 15px;
    color: white;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

i {
    cursor: pointer;
}

.heading h4 {
    padding-bottom: 15px;
    font-size: 20px;
    font-family: 700;
    /* text-transform: uppercase; */
    color: white;
}
.heading.contact h4 {
    padding-bottom: 15px;
    font-size: 40px;
    font-family: 700;
    /* text-transform: uppercase; */
    color: orange;
}
.heading.contact p {
    padding-bottom: 15px;
    font-size: 20px;
    color: white;
}

.heading .line {
    margin-bottom: 15px;
    width: 100%;
    height: 3px;
    background-color: whitesmoke;
    position: relative;
}

.heading .line::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 8%;
    height: 3px;
    background-color: orange;
}

.apple {
    font-size: 10px;
    background-color: orange;
    display: inline-block;
    padding: 3px;
    border-radius: 2px;
    color: white;
    z-index: 10;
    text-transform: uppercase;
}


.gray-p {
    font-size: 13px;
    color: white;
}

/* --TOP WEB */
.topweb {
    background-color: black;
    padding: 7px;
}

.topweb .wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    text-transform: capitalize;
}

.topweb .wrapper .top-web-left a {
    color: white;
    margin-right: 12px;
}

.topweb .wrapper .top-web-right a i {
    color: white;
    margin-left: 12px;
}

/* STICK NAVBAR */
header.sticky {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    transition: 0.5s;
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
}

/* GRID CONTAINER */
.grid-container {
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 25px;
    margin-top: 20px;
    /* transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke;
    border-radius: 10px; */
}

.grid-color {
    /* background-color: transparent; */
    border-radius: 10px;
    padding: 20px;
    /* margin-bottom: 10px; */
    /* transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke; */
}


/*  Grid 1 form*/
.grid-1-form {
    margin-top: 20px;
    background-color: transparent;
}

.grid-1-form iframe {
    width: 100%;
    max-height: 170px;
    margin-bottom: 20px;
}

.input-flex {
    display: flex;
    gap: 20px;
    
}

.inputBx {
    width: 100%;
    margin-bottom: 12px;
    
}

.inputBx input {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
    font-size: 15px;
}
.inputBx textarea {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    background-color: rgb(232, 231, 227);
    border-radius: 5px;
    font-size: 15px;
}

.inputBx input:focus,
textarea:focus {
    border: 1px solid orange;
}

textarea {
    width: 100%;
    max-height: 150px;
    resize: none;
    border: none;
    outline: none;
    background-color: rgb(236, 236, 236);
    border-radius: 5px;
    padding: 8px;
    font-size: 15px;
}

.input-button input {
    padding: 7px 25px;
    margin-top: 10px;
    font-size: 16px;
    background-color: orange;
    border: none;
    color: white;
    outline: none;
    border-radius: 5px;
}

.input-button input:hover {
    background-color: black;
    box-shadow: 0 0 15px #fc9917,
        0 0 25px #fc9917;
}

/* Grid-box-2 */
.grid-2-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 20px;
    padding: 10px;
    margin-top: 20px;
}

.grid-2-col {
    text-align: center;
}

.grid-2-col h4 {
    color: orange;
    margin: 10px 0;
}

.grid-2-col p {
    margin: 10px 0;
    text-align: center;
}

.grid-2-col i {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    background-color: orange;
    color: white;
}



/* SUPER GRID RIGTH */
.grid-9-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.grid-9-row .social-box a {
    overflow: hidden;
    border-radius: 3px;
    margin-right: 2px;
    font-size: 15px;
    color: white;
    cursor: pointer;
}

.grid-9-row .social-box i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: white;
}

.grid-9-row .social-box span {
    padding: 10px 15px;
}

.o-1 {
    background-color: rgba(243, 229, 229, 0.2);
}

.s-1 {
    background-color: #3b5999;
}

.s-2 {
    background-color: #00acee;
}

.s-3 {
    background-color: red;
}

.s-4 {
    background: linear-gradient(15deg, #ffb13d, #dd277b, #4d5ed4);
}

/* GRID-BOX-10*/
.grid-10-img {
    position: relative;
    height: 220px;
    margin-top: 20px;
    border-radius: 5px;
    overflow: hidden;
    /* transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke; */
}

.grid-10-img-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
}

.grid-10-img-content .apple {
    background-color: orangered;
    display: inline-block;
    font-size: 10px;
    color: white;
    padding: 2px;
    border-radius: 4px;
}

.grid-10-img-content h4 {
    color: white;
    margin: 10px 0;
}

.grid-10-img-content h5 {
    color: rgb(173, 169, 169);
}

/* grid-10-item */
.grid-10-item {
    display: flex;
    margin: 20px 0;
     display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 20px;
}

.grid-10-item-img {
    position: relative;
    width:250px;
    height: 130px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.grid-10-item-img img {
    width:100%;
    height: 150px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.grid-10-item-content h5 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.grid-10-item-content h5:hover {
    color: orangered;
    cursor: pointer;
}

.grid-10-item-content h6 {
    color: gray;
    font-size: 14px;
    font-weight: 200;
}
.grid-6-item-content h5 {
    color: whitesmoke;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}
.grid-6-item-content h6 {
    color: whitesmoke;
    font-size: 16px;
    font-weight: 200;
    margin-top: 10px;
}

.grid-10-img:hover img,
.grid-10-item:hover img {
    transform: scale(1.1);
    cursor: pointer;
}


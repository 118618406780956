.navbar{
  max-width: 1400px;
}
.container-contral {
  position: fixed;
  scroll-behavior: smooth;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  margin: 0;
  z-index: 999;
  top: 0%;
  display: flex;
  align-items: center;
  /* background: black; */
  box-shadow: 0px 2px 0px 0px orange;
	background:linear-gradient(to bottom, #000000 2%, black 100%);
	background-color:black;
	cursor:pointer;
	color:#333333;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	text-decoration:none;
	text-shadow:0px 0px 0px #f29d13;
}

.logo img {
  height: 65px;
  border-radius: 3px;
  width: auto;
  margin: 0px 20px;
  transition: .3s;
  opacity: 0;
  animation: slideRight 1s ease forwards;
}

.logo img:hover {
  color: white;
  border-radius: 5px;
  transform: translateY(-10px);
  box-shadow: 0 0 55px white,
    0 0 25px white, 0 0 50px white,
    0 0 100px white, 0 0 200px white;
}

/* .contral {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.menu {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  padding:0 40px;
  margin-top: 20px;
}

.menu a {
  color: orange;
  font-size: 25px;
  margin-right: 25px;
  font-weight: 600;
  transition: .3s;
  text-decoration: none;
  opacity: 0;
  animation: slideTop .5s ease forwards;
  animation-delay: calc(.1s * var(--1));
}

.menu a:hover {
  color: white;
  text-shadow: 0 0 55px white,
    0 0 25px white, 0 0 50px white,
    0 0 100px white, 0 0 200px white;
}

.menu-icon {
  font-size: 30px;
  color: orange;
  cursor: pointer;
  display: none;
}

.menu.open {
  /* position: absolute;
  height: auto;
  top: 72px;
  left: 10; */
  /* right: 0; */
  background: black;
  margin-top: 432px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  /* padding: 10px; */
  /* margin-left: 50px; */
}

.menu.open a {
  opacity: 1;
  color: white;
  display: flex;
  justify-content: left;
  padding: 15px;
  margin-right: 15px;
  width: 100%;

}

.menu-icon {
  display: none;
  flex-direction: column;
}

.search-container {
  display:flex;
  justify-content:right;
  width:100%;
  margin-right: 0;
  padding: 0;
  transition: .3s;
  opacity: 0;
  animation: slideLeft 1s ease forwards;
}

.search-container .search input {
  border: none;
  outline: none;
  height: 40px;
  width: auto;
  border-radius: 5px;
  padding: 10px;
}

.search-container .search-success  input {
  border: 1px solid orange;
  color: orange;
  background: none;
  max-width:40%;
  margin-left: 15px;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;

}


.search-container.open {
  position: fixed;
  top: 190px;
  left: 0;
  right: 0;
  background-color: black;
  color: white;
  padding: 10px;
  display: none;
}

.search-container.active {
  display: block;
}


.search-container .search-success input:hover {
  border: 1px solid orange;
  box-shadow: 0 0 25px white;
  background: orange;
  transition: 0.3s;
}

.search-container .search input:hover {
  border: 2px solid orange;
  box-shadow: 0 0 25px white;
  transition: 0.3s;
}

@media only screen and (max-width: 992px) {
  .menu {
    display: none;
  }

  .search-container {
    display: none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 30px;
  }

  .navbar.active .menu {
    display: flex;
  }

  .navbar.active .menu-icon {
    color: #fc9917;
  }
}




@keyframes slideLeft {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideTop {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slideBottom {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.Cards {
    width: 100%;
    margin: 10px 0;
}
.materials {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-gap: 10px;
    margin-bottom: 10px;

}
.Cards h4 {
    font-size: 35px;
    padding: 20px 0;
    color: orange;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card.m-2   {
    background: none;
    outline: none;
    border: 1px solid orange;
    transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke;
    min-width: 19rem;
    height: 26rem;
    margin: 0;
    padding: 0;
    border-radius: 15px;
}
.card.m-2 img {

    border-radius: 10px;
    transition: .3s;
    min-width: 15rem;
    height: 20rem;
    margin: 0;
    padding: 0;
    border-radius: 15px;
}


.card.m-2:hover {
    transform: translateY(-px);
}

.col {
    border-radius: 10px;
    margin: 10px;
    max-width: 35rem;
    height: auto;

}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.col:hover {
    transform: translateY(-10px);
    transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke;
}

.card.m-2:hover img {
    transform: scale(1.05);
    transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke;
}

.card-title {
    color: orange;
    font-weight: 600;
}

.btn.btn-warning {
    margin-top: 10px;
}

.container {
    margin: 20px 0;
}

.pb-4.border-bottom {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}
.grid-6-img-one img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
}
@media (max-width: 991px) {}
.Slider{
    text-align: center;
    padding: 0px;
    margin: 30px 0  30px 0;
    height: auto;
    transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke;
   
  }
  
  h1 {
    color: white;
    margin-bottom: 20px;
  }
  
  .logo-slide img {
    width:auto;
    height:70px;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-top: 50px;
  }
  .logo-slide :hover img {
    transform: scale(1.8);
    transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 10px whitesmoke;
   
}
  .Slideimage {
    text-align: center;
    padding: 20px;
  }
.slick-next{
  display: none !important;
  margin: 0%;
  padding: 0%;
  width: 100%;
}
.grid-color-ser {
    background-color: transparent;
    margin: 0 0 25px 0;
    padding: 20px;
}

.heading-flex-ser {
    display: flex;
    justify-content: space-between;
    align-items: first baseline;
}

.heading-flex-ser h3 {
    font-size: 35px;
    color: white;
    padding-bottom: 20px;
}

.heading-flex-ser p {
    font-size: 20px;
    color: white;
}

.grid-8-img-ser {
    margin-bottom: 20px;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}

.grid-8-img-ser img {
    padding: 0;
    height: 100%;
    width: 100%;
    padding-left: 0%;
}

/* .grid-8-mini-col {
    border: 1px solid orange;
    border-radius: 10px;
    padding: 5px;
} */


.grid-8-img-ser:hover img {
    transform: scale(1.02);
}
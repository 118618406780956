@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Oswald:wght@200;300;400&family=Roboto:wght@100&display=swap');

:root {
    --gray: rgb(70, 69, 69);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    transition: 0.5s;
}



.wrapper {
    max-width: 1400px;
    margin: auto;
    padding: 20px;
    padding-top: 30px;

}

p {
    color: var(--gray);
    font-size: 15px;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

i {
    cursor: pointer;
}

/* --TOP WEB */
.topweb {
    background-color: black;
    padding: 7px;
}

.topweb .wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    text-transform: capitalize;
}

a .topweb .wrapper .top-web-left a {
    margin-right: 12px;
}

.topweb .wrapper .top-web-right a i {
    color: white;
    margin-left: 12px;
}

/*----------- NAVIGATION BAR-------------- */
header {
    background-color: white;
    padding: 15px;
    width: 100%;
}

#bars,
#times {
    display: none;
}

header .wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

header .wrapper .logo {
    font-size: 25px;
    color: black;
    font-weight: 800;
}

header .wrapper .logo i {
    color: orangered;
}

header .wrapper .navbar {
    display: inline-flex;
}

header .wrapper .navbar li a {
    font-size: 14px;
    color: black;
    font-weight: 600;
    margin-left: 25px;
    text-transform: uppercase;
}

.logo-991 {
    display: none;
}

header .wrapper .navbar li a:hover,
.topweb .wrapper a:hover,
.topweb .wrapper a i:hover {
    color: orangered;
    transition: all 0.1s;
}

header .search {
    position: absolute;
    right: 0;
    background-color: #eeecec;
    padding: 8px;
    border-radius: 5px;
}

header .search i:hover {
    color: orangered;
}

/* STICK NAVBAR */
header.sticky {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    transition: 0.5s;
    box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
}

/*-----------link  ------------*/
.link-box {
    background-color: white;
    margin: 25px 0;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 15px;
    border-radius: 5px;
}

.mini-link-box-l span {
    color: orangered;
    font-size: 14px;
}

.mini-link-box-r span i {
    margin: 0 2px;
    background: rgb(221, 219, 219, 0.5);
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 14px;
}

.mini-link-box-r span i:hover {
    color: orangered;
}

/*------------ FIRST BOX -------------*/
.first-box {
    /* background-color: gray; */
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 200px 200px;
    gap: 5px;
    padding: 20px 0px;
    border-radius: 5px;
    /* transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke; */
}

.first-box-content {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.first-box-content::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 100%;
    z-index: 0;
}

.first-box-content img {
    border-radius: 10px;
}
.first-box-content:hover img {
    transform: scale(1.1);
}

.first-box-content:nth-child(1) {
    grid-row: 1/3;
}

.first-box-content:nth-child(2) {
    grid-column: 2/4;
    grid-row: 1/2;
}

.first-content {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 10px;
    color: white;
    z-index: 1;
}


.first-content h3 {
    font-size: 25px;
    margin: 10px 0;
}

.first-content h4 {
    margin: 8px 0;
    font-size: 16px;
}

.first-content p {
    color: rgb(190, 188, 188);
}

/* -----ALL-COMMAND---- */
.heading h4 {
    padding-bottom: 15px;
    font-size: 15px;
}

.heading-flex {
    display: flex;
    justify-content: space-between;
}

.heading-flex p:hover {
    color: orangered;
    cursor: pointer;
}

.heading .line {
    margin-bottom: 15px;
    width: 100%;
    height: 3px;
    background-color: whitesmoke;
    position: relative;
}

.heading .line::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 8%;
    height: 3px;
    background-color: orangered;
}

.gray-p {
    color: white;
    font-size: 64px;
}

.grid-color {
    background-color: transparent;
    margin: 0 0 25px 0;
    padding: 20px;
}

/*--------------------------*/

.grid-container {
    margin-top: 20px;
    gap: 25px;
    display: grid;
    grid-template-columns: 2fr 1fr;
   
}

.super-grid-left,
.super-grid-right {
    min-height: 600px;
    
}

/* -------------------------- */
/* GRID BOX  1*/
.grid-1-flex {
    display: flex;
    gap: 20px;
}

.grid-1-col {
    margin-top: 10px;
    width:850px;
    height:100%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
    background: none;
    /* transition: background 0.5s, transform 0.5s;
    box-shadow: 1px 1px 20px whitesmoke; */

}

.grid-1-col:hover img {
    transform: scale(1.1);
}

.grid-1-col::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 100%;
}

.grid-1-content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    z-index: 10;
}

.grid-1-content h4 {
    color: white;
    font-size: 20px;
    margin: 20px 0;
}

.grid-1-content h3 {
    color: white;
    font-size: 20px;
    margin: 20px 0;
}

/* mini-col */
.grid-1-mini-col {
    display: grid;
    grid-gap: 20px;
    margin-top: 20px;
}

.mini-content-1:hover h1 {
    color: orange;
    transition: .3s;
    cursor: pointer;
}

.mini-col-1 {
    display: flex;
    gap: 10px;
}

.mini-img-1 {
    overflow: hidden;
    max-width: 100px;
}

.mini-col-1:hover img {
    transform: scale(1.1);
}

.mini-content-1 {
    max-width: 900px;
    margin-top: 0;
    text-align: justify;
    height: auto;
}

.mini-col-1 .mini-content-1 h5 {
    width: auto;
    font-size: 16px;
    height: auto;
    margin-bottom: 10px;
    color: #16161a;
}

.mini-col-1 .mini-content-1 h1 {
    margin-bottom: 10px;
    justify-content: left;
    font-weight: 600;
}

/* GRID BOX 2 */
.grid-2-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.grid-2-img {
    height: 120px;
    position: relative;
    overflow: hidden;
}

.grid-2-col .apple {
    position: absolute;
    bottom: 0px;
    left: 0;
}

.grid-2-content h5 {
    font-size: 15px;
    margin: 8px 0 4px;
}

.grid-2-col:hover h5 {
    color: orangered;
    transition: .3s;
    cursor: pointer;
}

.grid-2-col:hover img {
    transform: scale(1.1);
}

/* GRID BOX 3 */
.grid-box-3 {
    margin-bottom: 25px;
}

.grid-3-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
}

.grid-3-col {
    padding: 20px;
    background-color: white;
}

.grid-3-img {
    position: relative;
    margin-bottom: 20px;
    height: 200px;
    overflow: hidden;
    cursor: pointer;
}

.grid-3-img::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    width: 100%;
    height: 100%;
    display: block;
}

.grid-3-content {
    position: absolute;
    bottom: 15px;
    right: 10px;
    left: 15px;
    z-index: 5;
}

.grid-3-content h4 {
    margin: 10px 0;
    color: white;
}

.grid-3-img:hover img {
    transform: scale(1.1);
}

/* Mini-row-3 */

.grid-3-mini-row {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.grid-3-mini-row:last-child {
    margin-bottom: 0px;
}

.grid-3-mini-img {
    min-width: 100px;
    max-width: 100px;
    overflow: hidden;
}

.grid-3-mini-content h5 {
    margin-bottom: 5px;
    font-size: 13px;
}

.grid-3-mini-row:hover img {
    transform: scale(1.1);
}

.grid-3-mini-row:hover h5 {
    color: orangered;
}

/* GRID BOX 4 */
.grid-4-container {
    display: flex;
    margin-bottom: 20px;
}

.image-4 .apple {
    position: absolute;
    bottom: 0;
    left: 0;
}

.grid-4-container {
    position: relative;
    gap: 20px;
}

.image-4 {
    position: relative;
    max-height: 130px;
    max-width: 250px;
    overflow: hidden;
}

.grid-4-container:hover img {
    transform: scale(1.1);
}

.grid-4-container:hover h3 {
    color: orangered;
}

.content-4 {
    margin: 10px 0;
}

.link {
    font-size: 12px;
}

/* GRID BOX 5 */
.grid-box-5 {
    margin-bottom: 25px;
    padding: 20px;
    background-color: #16161a;
    color: white;
}

.grid-5-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
}

.grid-5-col {
    grid-row: 1/3;
    position: relative;
    overflow: hidden;
}

.grid-5-col .grid-con {
    position: absolute;
    bottom: 15px;
    left: 15px;
    right: 10px;
}

.grid-con h4 {
    margin: 10px 0;
    font-size: 20px;
}

/* mini col */
.grid-5-mini-col .gray-p {
    font-size: 12px;
}

.grid-5-mini-col img {
    height: 100px;
}

.grid-5-mini-col h5 {
    margin: 5px 0;
}

.video i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: orangered;
    display: inline-block;
    width: 35px;
    height: 35px;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
}

.img-5 {
    position: relative;
    overflow: hidden;
    height: 100px;
}

.grid-5-col:hover img,
.grid-5-mini-col:hover img {
    transform: scale(1.05);
    cursor: pointer;
}

.grid-5-mini-col:hover h5 {
    color: orangered;
}

/* GRID BOX 6 */
.grid-6-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.grid-6-img {
    position: relative;
    overflow: hidden;
    height: 200px;
}

.grid-6-img .apple {
    position: absolute;
    bottom: 0;
    left: 0;
}

.grid-6-col h4 {
    margin: 10px 0;
    cursor: pointer;
    color: whitesmoke;
}

.grid-6-col:hover img {
    transform: scale(1.1);
}

.grid-6-col:hover h4 {
    color: orange;
}

/* GRID BOX 7 */
.grid-7-container {
    display: flex;
    gap: 20px;
    cursor: pointer;
    margin-bottom: 20px;
}

.grid-7-container:last-child {
    margin: 0;
}

.grid-7-img {
    position: relative;
    overflow: hidden;
    max-width: 230px;
    min-height: 150px;
}

.grid-7-img .apple {
    position: absolute;
    bottom: 0;
    left: 0;
}

.grid-7-content h3 {
    line-height: 25px;
    font-size: 20px;
}

.grid-7-content p {
    margin: 15px 0;
}

.grid-7-container:hover img {
    transform: scale(1.1);
}

.grid-7-container:hover h3 {
    color: orangered;
}

/* GRID BOX 8 */
.grid-8-img-box {
    position: relative;
    margin-bottom: 20px;
    height: 830px;
    border-radius: 10px;
    overflow: hidden;
}

.grid-8-mini-content {
    position: absolute;
    bottom: 20px;
    left: 15px;
    right: 15px;
    color: white;
    pad: 100px;
}

.grid-8-mini-content h2 {
    margin: 5px 0;
}

.grid-8-img-box:hover img {
    transform: scale(1.1);
}

/* Mini-row-8 */
.grid-8-mini-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.grid-8-mini-img {
    overflow: hidden;
    height: 200px;
    border-radius: 10PX;
    position: relative;
}

.grid-8-mini-col .apple {
    position: absolute;
    bottom: 0;
    left: 0;
}

.grid-8-mini-col h5 {
    margin: 5px 0;
    cursor: pointer;
}

.grid-8-mini-col:hover img {
    transform: scale(1.1);
}

.grid-8-mini-col:hover h5 {
    color: orange;
}

/*------ SUPER GRID RIGHT----- */
/* GRID BOX 9 */
.grid-9-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.grid-9-row .social-box {
    overflow: hidden;
    border-radius: 3px;
    margin-right: 2px;
    font-size: 15px;
    color: white;
    cursor: pointer;
}

.grid-9-row .social-box i {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: white;
}

.grid-9-row .social-box span {
    padding: 10px 15px;
}

.o-1 {
    background-color: rgba(243, 229, 229, 0.2);
}

.s-1 {
    background-color: #3b5999;
}

.s-2 {
    background-color: #00acee;
}

.s-3 {
    background-color: red;
}

.s-4 {
    background: linear-gradient(15deg, #ffb13d, #dd277b, #4d5ed4);
}

/* GRID-BOX-10*/
.grid-10-img {
    position: relative;
    height: 180px;
    overflow: hidden;
}

.grid-10-img-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
}

.grid-10-img-content .apple {
    background-color: orangered;
    display: inline-block;
    font-size: 10px;
    color: white;
    padding: 2px;
    border-radius: 4px;
}

.grid-10-img-content h4 {
    color: white;
    margin: 10px 0;
}

.grid-10-img-content h5 {
    color: rgb(173, 169, 169);
}

/* grid-10-item */
.grid-10-item {
    display: flex;
    margin: 30px 0;
}

.grid-10-item-img {
    width: 200px;
    height: 70px;
    margin-right: 15px;
    overflow: hidden;
}

.grid-10-item-content h5 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.grid-10-item-content h5:hover {
    color: orangered;
    cursor: pointer;
}

.grid-10-item-content h6 {
    color: gray;
    font-size: 14px;
    font-weight: 200;
}

.grid-10-img:hover img,
.grid-10-item:hover img {
    transform: scale(1.1);
    cursor: pointer;
}

/* GRID BOX 11 */
.grid-11-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 10px;
}

.grid-11-img {
    position: relative;
}

.people {
    position: absolute;
    bottom: 0;
    font-size: 9px;
    letter-spacing: 1px;
    background-color: orangered;
    color: white;
    padding: 3px;
}

.grid-11-img {
    height: 95px;
    overflow: hidden;
}

.grid-11-col h4 {
    font-size: 14px;
    margin: 10px 0;
    cursor: pointer;
}

.grid-11-col h6 {
    color: rgb(172, 167, 167);
    font-weight: 200;
    font-size: 13px;
}

.grid-11-col:hover h4 {
    color: orangered;
}

.grid-11-col:hover img {
    transform: scale(1.1);
    cursor: pointer;
}

/* GRID BOX 12 */
.grid-box-12 {
    border-top: 3px solid black;
    position: relative;
}

.email-12 {
    position: absolute;
    top: 20px;
    right: 20px;
    transform: rotate(45deg);
    font-size: 40px;
    color: rgb(226, 222, 222, 0.5);
}

.grid-box-12 p {
    margin: 15px 0;
}

.grid-box-12 .input-12 input {
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    outline: none;
}

.grid-box-12 .input-12 input:focus {
    border: 1px solid orangered;
}

.grid-box-12 .btn {
    background-color: orangered;
    width: 100%;
    display: inline-block;
    text-align: center;
    color: white;
    padding: 8px;
    transition: 0.2s;
}

.grid-box-12 .btn:hover {
    background-color: rgb(231, 66, 6);
}

/* GRID BOX 9 */
.mini-box-13 {
    display: flex;
    margin-top: 20px;
    cursor: pointer;
}

.img-box-13 img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.mini-box-13 .box-13-content h5:hover {
    color: orangered;
}

.mini-box-13 .box-13-content p {
    font-size: 13px;
    margin-top: 3px;
}

/* GIRD BOX  10*/
.grid-14-content li {
    margin-bottom: 12px;
    color: rgb(105, 105, 105);
    font-weight: 500;
}

.grid-14-content li span {
    font-size: 14px;
    transition: 0.01s;
    color: black;
}

.grid-14-content li:hover {
    color: orangered;
}

.grid-14-content li span {
    float: right;
}

/* GRID BOX 11 */
.grid-15-row {
    display: flex;
    flex-wrap: wrap;
}

.grid-15-col {
    background-color: rgb(243, 242, 242);
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
}

.grid-15-col:hover {
    color: orangered;
    cursor: pointer;
}


/* --------------FOOTER  BOX --------*/
footer {
    background-color: #1f1e1e;
}

footer .wrapper .footer-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 40px;
    color: white;
    gap: 30px;
    font-size: 15px;
}

.footer-grid .footer-col {
    margin-top: 30px;
}

.footer-col h4 {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 15px;
}

/* footer 1 */
.footer-1 ul {
    margin: 20px 0;
}

.footer-1 ul li {
    color: white;
    margin: 10px 0;
}

.footer-1 ul li span {
    float: right;
    color: rgb(141, 140, 140);
}

.footer-1 ul li:hover,
.f-main-col:hover {
    color: orangered;
    cursor: pointer;
}

.f-main-row {
    display: flex;
    flex-wrap: wrap;
}

.f-main-col {
    background-color: #2e2d2d;
    padding: 4px;
    margin: 5px;
    border-radius: 5px;
}

/* footer 2 */
.footer-2-box {
    display: flex;
    margin-bottom: 25px;
    cursor: pointer;
}

.footer-img {
    min-width: 100px;
    overflow: hidden;
}

.footer-img img {
    width: 100px;
}


.footer-content {
    margin-left: 20px;
}

.footer-content p {
    font-size: 12px;
    margin-top: 5px;
    color: gray;
}

.footer-2-box:hover h5 {
    color: orangered;
}

.footer-2-box:hover img {
    transform: scale(1.1);
}

/*  last-footer*/
.last-footer {
    border-top: 1px solid rgb(233, 232, 232, 0.2);
    margin-top: 40px;
    padding: 40px 0;
}

.last-footer-box {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.mini-last-footer-box {
    display: flex;
    align-items: center;
}

.last-box-content {
    margin-left: 20px;
}

.mini-last-footer-box .logo {
    color: white;
    min-width: 180px;
    font-size: 25px;
    font-weight: 600;
}

.mini-last-footer-box i {
    color: orangered;
}

.last-box-content h4 {
    color: white;
    margin-bottom: 10px;
}

.icon-footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.icon-footer i {
    font-size: 16px;
    margin-left: 10px;
    color: white;
    padding: 8px;
    background-color: rgba(114, 114, 114, 0.1);
    transition: 0.1s;
}

.icon-footer i:nth-child(1):hover {
    background-color: #254c94;
}

.icon-footer i:nth-child(2):hover {
    background-color: #22befc;
}

.icon-footer i:nth-child(3):hover {
    background: linear-gradient(15deg, #ffb13d, #dd277b, #4d5ed4);
}

.icon-footer i:nth-child(4):hover {
    background-color: #9b1c21;
}

.icon-footer i:nth-child(5):hover {
    background-color: #ca2127;
}

/* END */
.end {
    background-color: rgb(5, 5, 5);
}

.end .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px;
}

.end .wrapper p {
    color: white;
}

.end .wrapper ul {
    display: flex;
}

.end .wrapper ul li a {
    margin-left: 10px;
    color: white;
    font-size: 14px;
}

/* 
/*-------------- RESPONSIVE--------- */

/* Max-wuidth 880px */
@media(max-width:880px) {
    .grid-container {
        grid-template-columns: minmax(10px, 1fr);
    }
    .Solution-contrall{
        padding: 10px;

    }
    .wrapper {
        padding: 0 20px;
    }

    /* Footer */
    footer .wrapper .footer-grid {
        grid-template-columns: 1fr;
    }

    .last-footer-box {
        display: grid;
        grid-template-columns: 1fr;
        text-align: center;
    }

    .mini-last-footer-box {
        display: block;
    }

    .last-box-content h4 {
        display: none;
    }

    .last-box-content p {
        margin: 20px 0;
    }

    .icon-footer {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    /* End wrapper */
    .end .wrapper {
        display: flex;
        flex-direction: column;
    }

    .end .wrapper ul {
        margin-top: 20px;
        order: 1;
    }
}


/* Max Width 680px */
@media(max-width:680px) {
    .wrapper {
        padding: 0 10px;
    }

    .link-box {
        display: none;
    }
    .Solution-contrall{
        padding: 10px;

    }

    .first-box {
        grid-template-columns: 1fr 1fr;
        margin-top: 20px;
    }

    .first-content h3 {
        font-size: 16px;
    }

    .first-box-content:nth-child(1) {
        grid-row: 1/2;
    }

    /* ---GRID BOX 1--- */
    .grid-1-flex {
        display: block;
    }

    .grid-1-mini-col {
        margin-top: 20px;
    }

    /* GRID BOX 2 */
    .grid-2-row {
        grid-template-columns: repeat(2, 1fr);
    }

    /* GRID BOX 3 */
    .grid-3-row {
        grid-template-columns: 1fr;
    }

    /* GRID BOX  4*/
    .grid-4-container {
        display: block;
    }

    .image-4 {
        position: relative;
        max-height: 200px;
        max-width: 100%;
        overflow: hidden;
    }

    .mini-4-content {
        margin: 20px 0 30px;
    }

    /* GRID BOX 5 */
    .grid-box-5 {
        margin-bottom: 25px;
        padding: 20px;
        background-color: #16161a;
        color: white;
    }

    .grid-5-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        gap: 20px;
    }

    .grid-5-col {
        grid-row: 1/2;
        grid-column: 1/3;
        max-height: 200px;
    }

    .grid-5-col .grid-con {
        position: absolute;
        bottom: 15px;
        left: 15px;
        right: 10px;
    }

    .grid-con h4 {
        margin: 10px 0;
        font-size: 20px;
    }

    /* mini col */

    .grid-5-mini-col img {
        height: 100px;
    }

    .grid-5-mini-col h5 {
        margin: 5px 0;
    }


    .img-5 {
        position: relative;
        overflow: hidden;
        height: 100px;
    }

    /* GRID BOX  6*/


    /* GRID BOX  7*/
    .grid-7-container {
        display: block;

    }

    .grid-7-img {
        position: relative;
        overflow: hidden;
        max-width: 100%;
        height: 200px;
    }

    .grid-7-content h3 {
        margin-top: 20px;
        line-height: 25px;
        font-size: 20px;
    }

    /* GRID BOX 8 */
    .grid-8-img-box {
        position: relative;
        height: 230px;
    }

    .grid-8-mini-content h2 {
        font-size: 18px;
    }

    /* Mini-row-8 */
    .grid-8-mini-row {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 10px;
    }

    .grid-8-mini-col h5 {
        margin: 5px 0;
    }
}



/* Max width 480px */
@media(max-width:480px) {

    /* .wrapper{border: 1px solid red;} */
    nav .wrapper .logo {
        font-size: 20px;
    }

    .grid-color {
        padding: 15px;
    }

    /* GRID BOX 1 */
    .first-box {
        display: block;
        overflow: hidden;
    }

    .grid-1-content h4 {
        font-size: 16px;
    }

    .grid-2-row,
    .grid-6-row,
    .grid-8-mini-row,
    .grid-9-row {
        grid-template-columns: 1fr;
    }

    .grid-5-content h5 {
        font-size: 12px;
    }

    .grid-con h4 {
        font-size: 15px;
    }



    .grid-11-col h4 {
        font-size: 13px;
    }

    header .wrapper .logo {
        font-size: 20px;
    }

    .first-box {
        padding: 10px;
    }
}
.contact-icons i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 2px solid white;
    border-radius: 20%;
    font-size: 20px;
    color: white;
    text-decoration: none;
    margin: 20px 15px 0px 0;
    transform: .5s ease;
    opacity: 0;
    animation: slideLeft 1s ease forwards;
    animation-delay: calc(.2s * var(--1));
}
.contact-icons{
    justify-content: center;
    margin-left: 0px;
}
.contact-icons i:hover {
    background: orange;
    color: white;
    box-shadow: 0 0 20px orange;

}
.contact-icons .active {
    --1: 6;
    --2: 7;
    --3: 8;
    --4: 9;
  }
/* src/MoneyPage.css */
.money-page {
    padding: 20px;
    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  
  .money-page h1 {
    color: whitesmoke;
    margin: 30px 0px;
  }
  .money-page h2 {
    color: orange;
    margin: 30px 0px;
  }
  
  .money-page p {
    color: white;
    line-height: 1.5; 
  }
  .money-page img {
    width:100%;
    height:auto;
    margin: 0%;
    padding: 0%;
  }

  
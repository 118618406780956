.container-home {
  width: 100%;
  
}
.intrall-home.lefe:hover {
  transform: translateX(50px);
  transition: .3s;
  /* transition: background 0.5s, transform 0.5s;
  box-shadow: 0px 0px 20px 0 whitesmoke; */
}
.intrall-home.right:hover {
  transform: translateX(50px);
  transition: .3s;
  /* transition: background 0.5s, transform 0.5s;
  box-shadow: 0px 0px 20px 0 whitesmoke; */
}
.intrall-top:hover {
  transition: background 0.5s, transform 0.5s;
  box-shadow: 0px 0px 20px 0px whitesmoke;
  transition: .3s;
  
}
.slick-next{
  display: none !important;
}
.intrall-top {
  will-change: 100%;
}